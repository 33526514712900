import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"
import "../components/cms/cms.scss"
import StaticHero from "../components/common/staticHero"
import { Container, Row } from "react-bootstrap"
import { formatBytes } from "../components/functions"
import { getSrc } from "gatsby-plugin-image"

export default function PressRelease({
  data: { nodePressRelease },
  pageContext,
}) {
  const isSSR = typeof window === "undefined"

  const BlockWysiwyg = React.lazy(() =>
    import("../components/cms/blockWysiwyg")
  )

  const {
    title,
    field_seo,
    press_release_description: body,
    press_release_link,
    relationships: {
      press_release_media: bannerImage,
      press_release_documents,
    },
  } = nodePressRelease

  const intl = useIntl()

  const seoData = setSeoData({
    lang: intl.locale,
    title: title,
    field_seo: field_seo,
    image:
      process.env.GATSBY_URL + bannerImage?.relationships?.field_image?.lgatsbyImage,
  })

  return (
    <Layout pageContext={pageContext} pageTitle={title} pageClass={`press_release`}>
      <Seo data={seoData} pageContext={pageContext} />
      {bannerImage && (
        <StaticHero
          title={title}
          background={getSrc(bannerImage?.relationships?.field_image?.gatsbyImage)}
        />
      )}
      {!isSSR && (
        <React.Suspense fallback="loading.... ">
          <BlockWysiwyg customClass="blk-spacing" text={body.value} />
          <section className="pb-50">
            <Container>
              <Row>
                <div className="cta-award d-flex">
                  {press_release_link && (
                    <a
                      class="simpleLink linkExternal"
                      target="_blank"
                      href={press_release_link.url}
                    >
                      <span>{press_release_link.title}</span>
                    </a>
                  )}
                  {press_release_documents && (
                    <a
                      class="simpleLink linkDownload"
                      target="_blank"
                      href={
                        press_release_documents.relationships.field_media_file.url
                      }
                    >
                      <span>
                        {intl.formatMessage({ id: "listing.download" })}{" - "}{formatBytes(press_release_documents.relationships.field_media_file.filesize)}
                      </span>
                    </a>
                  )}
                </div>
              </Row>
            </Container>
          </section>
        </React.Suspense>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    nodePressRelease(id: { eq: $id }) {
      title
      field_seo {
        description
        title
        og_description
        og_image
        og_locale
        og_url
        og_title
        og_type
      }
      path {
        alias
        langcode
      }
      press_release_description {
        value
      }
      press_release_link {
        url
        title
      }
      relationships {
        press_release_categories {
          name
        }
        press_release_documents {
          relationships {
            field_media_file {
              filesize
              url
            }
          }
        }
        press_release_media {
          field_image {
            alt
          }
          relationships {
            ...getHeroCms
          }
        }
      }
    }
  }
`
