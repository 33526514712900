import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Slider from "react-slick"
import PropTypes from "prop-types"

const StaticHero = props => {
  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    fade: true,
    speed: 1000,
    lazyLoad: true,
    autoplaySpeed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <section
      className="mainBanner mainBannerThank">
      <Slider {...settings}>
        <div className="slider-element">
          <div
            className="mainBanner-infos"
            style={{
              backgroundImage:
                "linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 75%), url(" +
                props.background +
                ")",
            }}
          >
            <Container>
              <Row className="justify-content-center alignCenter">
                <Col md={10} xs={12}>
                  {props.caption && (
                    <span className="caption">{props.caption}</span>
                  )}
                  {props.title && <h2 className="mainTitle">{props.title}</h2>}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Slider>
      <span className="scrollElem"></span>
    </section>
  )
}

StaticHero.propTypes = {
  background: PropTypes.any.isRequired,
}

export default StaticHero
